<script>
import { mapActions } from 'vuex';

export default {
  created() {
    this.clearAuth();
    this.resetScheduling();
  },

  mounted() {
    this.$router.push({ name: 'UserLogin' });
  },

  methods: {
    ...mapActions('user', ['clearAuth']),
    ...mapActions('scheduling', ['resetScheduling']),
  },

  render: () => null,
};
</script>
